.card-component {
    cursor: pointer;
}

.card-component:hover {
    transform: scaleY(1.1);
}

.card-container {
    background: #f8f9fa;
    margin: 10px;
    padding: 20px;
    
}
.animate__animated.animate__shakeX {
  text-align: center;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;

  /* border: 1px solid rgb(97, 91, 85); */
  --animate-duration: 2s;
  --animate-delay: 1s;
  --animate-repeat: 1;
}

.link {
  color: red;
  text-decoration: none;
}

.animation-container {
  background-color: #000;
}

.course-card {
    border-radius: 10px;
    text-align: center;
    color: black;
    
}

.course-card:hover {
    cursor: pointer;
    box-shadow: 0.3rem 0.3rem gray;
}

.course-container {
    margin:10px;
    padding: 20px;
    /* background-image: radial-gradient( circle farthest-corner at 10% 20%,  rgba(255,209,67,1) 0%, rgba(255,145,83,1) 90% ); */

}

.course-card a {
    text-decoration: none;
    color : black;
}

.course-card a:hover {
    color: black;
}

.courses-card {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows:auto;
}
  
  
     
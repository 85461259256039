.mock-container {
    margin : 10px;
    padding: 10px;
    
}

.mock-cards{
    cursor: pointer;
    text-transform: none;

}
.cart-dropdown-container {
    position: absolute;
    width: 240px;
    height: 320px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    border: 1px solid black;
    background-color: white;
    top: 60px;
    right: 20px;
    z-index: 5;
}
    
.empty-message {
      font-size: 18px;
      margin: 50px auto;
    }
  
.cart-items {
      height: 240px;
      display: flex;
      flex-direction: column;
      overflow: scroll;
    }
  
button {
      margin-top: auto;
    }
 
 @media only screen and (max-width:600px) {
  .cart-dropdown-container {
    width: 200px;
    height: 250px;
    padding: 20px;
    top: 220px;
    right: 20px;
    z-index: 5;
}


  
 }   
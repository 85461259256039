h2 {
    text-align: center;
}

.contact-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 100px;
    border-radius: 10px;
    padding: 10px;
    /* background-image: radial-gradient( circle 400px at 6.8% 8.3%,  rgba(255,244,169,1) 0%, rgba(255,244,234,1) 100.2% ); */
}


.address-container{
    border:2px solid grey;
    padding:10px;
    border-radius: 10px; 
    margin-top:30px;
    display:flex;
    flex-direction:column;
    row-gap:20px;
    font-size: larger;
}

@media only screen and (max-width:600px) {
    .contact-container {
        grid-template-columns: 1fr;
        row-gap: 20px;
    }

}
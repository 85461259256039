.cart-icon-container {
    width: 40px;
    height: 40px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    


}
  
    .shopping-icon {
      width: 35px;
      height: 35px;
      
    }
  
    .item-count {
      position: absolute;
      font-size: 12px;
      font-weight: bold;
      bottom: 10px;
    }
  
.wbcs-title {
    display: flex;
    flex-direction: column;
    margin : 20px;
    /* background-color: #ff80cc; */
}

.wbcs-study-button {
    display: flex;
    
    justify-content: center;
}

.wbcs-study-tabs {
    margin : 20px;
}
.quiz-container {
    margin: 10px;
    padding:10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.quiz-card {
    width : 600px;
    max-width: 900px;
    padding: 20px 30px;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.4);
    box-shadow: 0 10px 10px 10px rgba(0, 0, 0, 0.2);

}

.quiz-option{
    cursor: pointer;
}


.footer-container {
  padding: 5px;
  background-color: #dcdcdc;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 10px;
}

.footer-middle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
}

.footer-bottom {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
}

.footer-middle li {
  list-style: none;
  text-align: center;
}
.copyright {
  text-align: center;
  background-color: orange;
  padding: 5px;
  font-weight: bold;
}

.footer-link {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87);
}

@media only screen and (max-width: 600px) {
  .footer-container {
    display: inline-block;
    font-size: 16px;
  }
  .follow-us {
    margin: 0;
  }
}

.nav-link {
    text-decoration: none;
    color:black !important;
    
}

.nav-link2 {
    color:black;
}








/* .nav-link-container {
    display: flex;
    flex-direction: row;
    column-gap: 5px; 
    font-weight: bold; 
    
} */

/* @media only screen and (max-width:600px) {
    .nav-link-container {
        display: inline;
        
    }

} */